export const useRole = () => {
    const user = useSupabaseUser()
    const supabase = useSupabaseClient()
    const userRoles = useState<string[]>('user-roles', () => [])

    const hasRole = (role: string) => userRoles.value.includes(role)

    watchEffect(async () => {
        if (user.value && userRoles.value.length === 0) {
            const { data, error } = await supabase
                .from('auth_user')
                .select('*')
                .filter('id_auth', 'eq', user.value.id)
            
            if (error) {
                console.log(error)
            } else {
                // @ts-expect-error
                userRoles.value = data?.[0]?.role
            }
        }
    })

    return { hasRole }
}

<script lang="ts" setup>
import type { LabeledRadioOption } from '~~/types/labeled-radio'

const props = defineProps<{
    label?: string
    options: LabeledRadioOption[]
    modelValue?: string
}>()
const emit = defineEmits(['update:modelValue'])

const selected = ref<number>(0)

onMounted(() => {
    const selectedValue = props.modelValue
        ? props.options.findIndex(option => option.value === props.modelValue)
        : null
    if (selectedValue === null) {
        selected.value = 0
        emit('update:modelValue', props.options[0].value)
    }
    else { selected.value = selectedValue }
})

const onItemClick = (index: number) => {
    selected.value = index
    emit('update:modelValue', props.options[index].value)
}
</script>

<template>
    <div>
        <div v-if="label" my-1 text-textcolor-700 text-3>
            {{ label }}
        </div>
        <div
            flex flex-gap-2
        >
            <div
                v-for="option, i in options"
                :key="option.value"
                name="option"
                text-textcolor-500
                b-1 b-primary-100 rounded-full
                bg-backgroundlight min-h-8
                flex items-center justify-center
                text-13px grow text-center font-600
                leading-2 transition
                :class="[{
                    [`
                    bg-primary-100 !text-primary b-primary-300
                `]: i === selected,
                }]"
                @click="() => onItemClick(i)"
            >
                {{ option.label }}
            </div>
        </div>
    </div>
</template>

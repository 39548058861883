<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type { Provider } from '~~/composables/services/provider-service'
import type { SelectItem } from '~~/types/select'

const props = defineProps<{
    error?: string | false
    label?: string
    placeholder?: string
    modelValue?: string
    withAdd?: boolean
    ableToShop?: boolean
}>()
const emit = defineEmits(['update:modelValue', 'selected', 'clear'])
const { providers, providersAbleToShop} = storeToRefs(useProviderStore())
const { success } = useNotification()
const { createProvider } = useProviderStore()

const internalValue = ref()

const defaultProviderList = computed<SelectItem<Provider>[]>(() => {
    if (providersAbleToShop.value && props.ableToShop) {
        return providersAbleToShop.value.map(providersAbleToShop => ({
            label: providersAbleToShop.name,
            value: providersAbleToShop.id_provider,
            metadata: providersAbleToShop,
        }))
    }

    else if (providers.value) {
        return providers.value.map(provider => ({
            label: provider.name,
            value: provider.id_provider,
            metadata: provider,
        }))
    }
})

const onAddProvider = async (providerName: string) => {
    const provider = await createProvider(providerName)
    success(`Fornecedor ${providerName} com sucesso!`)
    internalValue.value = provider.id_provider
}

watchEffect(() => {
    if (props.modelValue) {
        internalValue.value = props.modelValue
    }
})

watch([internalValue], ([newValue]) => {
    emit('update:modelValue', newValue)
})

onMounted(() => {
    if (props.modelValue) {
        internalValue.value = props.modelValue
    }
})

const onSelected = (item: SelectItem<Provider>) => {
    emit('selected', item.metadata)
}
</script>

<template>
    <cc-select
        v-model="internalValue"
        :error="error"
        :items="defaultProviderList ?? []"
        :on-add="withAdd ? onAddProvider : false"
        :label="label ?? 'Fornecedor'"

        :placeholder="placeholder ?? 'Selecione um fornecedor'"
        @selected="onSelected"
        @clear="$emit('clear')"
    />
</template>

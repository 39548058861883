const FEATURE_FLAGS = [
    'disable-shopper-filter',
    'disable-shopper-location-name',
] as const

export type FeatureFlag = typeof FEATURE_FLAGS[number]

export const useFeatureFlag = () => {
    const { public: config } = useRuntimeConfig()

    const isFeatureSet = (feature: FeatureFlag) => {
        return config.featureFlags.includes(feature)
    }

    return { isFeatureSet }
}
